import React from 'react'
import {getDay, getEnumPart, getTime} from '_helper/calendarFunctions'
import moment from 'moment'
import {ZONES} from '_helper/constants'
import {animated} from 'react-spring'

const WeekEventsVertical = (props) => {
  const {events, transitionProps} = props

  const getLeftColumn = (e) => {
    const leftEventsReduced = e.reduce((acc, d) => {
      const found = acc.find((a) => a.date === d.date)

      if (!found) {
        acc.push({date: d.date, events: [d.events]})
      } else {
        found.events.push(d.events)
      }
      return acc
    }, [])

    return leftEventsReduced
  }

  return (
    <animated.div style={transitionProps} className="left-column">
      {events &&
        getLeftColumn(events).map((event, index) => (
          <div className="event" key={index}>
            <div className="day-date">
              <div className="day">{event.date && getDay(event.date)}</div>
              <div className="date">{event.date && moment(event.date).format('DD. MM. YYYY')}</div>
            </div>

            <div className="event-name-time">
              {event.events.map((event, index) => (
                <div className="name-time" key={index}>
                  <div className="name"> {event.name}</div>
                  <div className="time"> {event.from && getTime(event.from)}</div>
                </div>
              ))}
            </div>

            <div className="zone">
              {event.events && (
                <img src={getEnumPart(ZONES, event.events[0].zone, 'icon')} alt="even-icon" />
              )}
            </div>
          </div>
        ))}
    </animated.div>
  )
}

export default WeekEventsVertical
