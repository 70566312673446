import {getServiceUrl} from '_helper/functions'

const endpoints = {
  events: `${getServiceUrl('public')}/events`,
  currentEvents: `${getServiceUrl('public')}/events/current`,
  futureEvents: `${getServiceUrl('public')}/calendar/future-week`,
  calendar: `${getServiceUrl('public')}/calendar`,
}

export default endpoints
