import React, {useCallback, useEffect, useState} from 'react'
import WeekEventsScreen from '_component/sideScreen/WeekEventsScreen'
import CurrentEventsScreen from '_component/sideScreen/CurrentEventsScreen'
import PropTypes from 'prop-types'

const SideScreenPage = (props) => {
  const {
    location: {search},
  } = props

  // tl = top left, tr = top right
  // bl = bottom left, br = bottom right
  const configurations = ['tl', 'tr', 'bl', 'br']
  const [config, setConfig] = useState({tl: 'week', tr: 'current', bl: 'current', br: 'week'})

  const prepareScreens = useCallback(() => {
    const paramsArray = search.substring(1).split('&')

    const params = {...config}

    paramsArray.forEach((param) => {
      const paramSplit = param.split('=')
      // ensure that params are valid
      if (configurations.includes(paramSplit[0])) {
        params[paramSplit[0]] = paramSplit[1]
      }
    })
    setConfig(params)
  }, [])

  useEffect(() => {
    if (search) {
      prepareScreens()
    }
  }, [prepareScreens])

  const renderScreens = () => {
    const screens = []
    for (let screen in config) {
      if (config[screen] === 'week') {
        screens.push(<WeekEventsScreen key={`week${Math.random()}`} />)
      } else {
        screens.push(<CurrentEventsScreen key={`current${Math.random()}`} />)
      }
    }
    return screens
  }

  return <div className="side-screen">{renderScreens()}</div>
}

SideScreenPage.propTypes = {
  search: PropTypes.string,
}

export default SideScreenPage
