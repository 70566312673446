import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import httpClient from '_helper/httpClient'
import endpoints from '_config/endpoints'
import {getDay, getDayAndMonthDate, getEnumPart} from '_helper/calendarFunctions'
import {EVENT_TYPES, ZONES} from '_helper/constants'
import {getTimeFrom} from '_helper/functions'
import moment from 'moment'
import WhatIsMagentaElement from '_component/sideScreen/WhatIsMagentaElement'
import Footer from '_component/Footer'
import Loader from '_component/Loader'

const SingleEventPrint = () => {
  let {id, date} = useParams()

  const [loading, setLoading] = useState(false)
  const [eventData, setEventData] = useState({})

  useEffect(() => {
    if (id && date && !loading) {
      setLoading(true)
      httpClient
        .get(`${endpoints.events}/${id}/date/${date}`)
        .then((res) => {
          setLoading(false)
          setEventData(res.data)
        })
        .catch((err) => {
          setLoading(false)
          console.error(err.response)
        })
    }
  }, [])

  return (
    <div className="print-single-layout">
      {loading ? (
        <Loader />
      ) : (
        <>
          <WhatIsMagentaElement />
          <div className="current-events-page">
            <div className="header">
              {eventData?.zone && (
                <img src={getEnumPart(ZONES, eventData.zone, 'icon')} alt="event-logo" />
              )}
            </div>
            <div className="event-detail">
              <div className="event-date-time">
                <div className="day">{eventData?.from && getDay(eventData.from)}</div>
                <div className="date">{eventData?.from && getDayAndMonthDate(eventData.from)}</div>
                <div className="time">{eventData && getTimeFrom(eventData)}</div>
              </div>
              <div className="event-type-description">
                <div className="type">{eventData.name && eventData.name}</div>
                <div className="description">
                  <span>{eventData?.organization?.name}&ensp;&ndash;&ensp;</span>
                  {eventData.type && getEnumPart(EVENT_TYPES, eventData.type, 'label')}
                </div>
              </div>
            </div>
            <div className="transparent-image">
              {eventData.horizontalPictureUrl && (
                <img
                  src={eventData.horizontalPictureUrl + '?timestamp=' + moment()}
                  alt="background"
                />
              )}
            </div>
          </div>
        </>
      )}
      <Footer justify="spaced" />
    </div>
  )
}

export default SingleEventPrint
