import React, {useEffect, useState} from 'react'
import {getDay, getDayAndMonthDate, getEnumPart, getEventType} from '_helper/calendarFunctions'
import {getScreenTimeoutTime, getTimeFrom} from '_helper/functions'
import {ZONES} from '_helper/constants'
import {useTransition, animated, config} from 'react-spring'
import PropTypes from 'prop-types'
import moment from 'moment'

const CurrentEvents = (props) => {
  const {events} = props

  const [displayedEventIndex, setDisplayedEventIndex] = useState(0)

  const setIndex = (prev) => {
    if (prev >= events.length - 1) {
      return 0
    } else {
      return prev + 1
    }
  }

  useEffect(() => {
    setInterval(() => {
      setDisplayedEventIndex((prev) => setIndex(prev))
    }, getScreenTimeoutTime())
  }, [])

  const transitions = useTransition(
    events && events[displayedEventIndex],
    (item) => item && item.id,
    {
      from: {opacity: 0, transform: 'translateX(-1080px)'},
      enter: {opacity: 1, transform: 'translateX(0px)'},
      leave: {opacity: 0, transform: 'translateX(1080px)'},
      config: config.slow,
    }
  )

  return (
    <div className="current-events-page">
      {transitions.map(({item, props, key}) => (
        <animated.div style={props} key={key} className="transition">
          <div className="header">
            {item?.zone && <img src={getEnumPart(ZONES, item.zone, 'icon')} alt="event-logo" />}
          </div>
          <div className="event-detail">
            <div className="event-date-time">
              <div className="day">{item?.from && getDay(item.from)}</div>
              <div className="date">{item?.from && getDayAndMonthDate(item.from)}</div>
              <div className="time">{item && getTimeFrom(item)}</div>
            </div>
            <div className="event-type-description">
              <div className="type">{item.name && item.name}</div>
              <div className="description">
                <span>{item?.organization?.name}&ensp;&ndash;&ensp;</span>
                {item?.type && getEventType(item)}
              </div>
            </div>
          </div>
          <div className="transparent-image">
            {item.horizontalPictureUrl && (
              <img src={item.horizontalPictureUrl + '?timestamp=' + moment()} alt="background" />
            )}
          </div>
        </animated.div>
      ))}
    </div>
  )
}

CurrentEvents.propTypes = {
  events: PropTypes.array,
}

export default CurrentEvents
