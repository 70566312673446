import React from 'react'
import {ENVIRONMENT, GATEWAY_URL, SIDE_SCREEN_TIMEOUT, TIME_FORMAT} from '_helper/constants'
import {getTime} from '_helper/calendarFunctions'
import {Trans} from '@lingui/macro'
import moment from 'moment'

export const resolveEnvironmentParam = (name) => {
  const nameWithPrefix = `REACT_APP_${name}`
  if (ENVIRONMENT === 'production') {
    return window._env_[nameWithPrefix]
  }
  return process.env[nameWithPrefix]
}

export const getServiceUrl = (name) => {
  return `${GATEWAY_URL}/api/${name}`
}

export const getScreenTimeoutTime = () => {
  return SIDE_SCREEN_TIMEOUT ? SIDE_SCREEN_TIMEOUT : 30000
}

export const getTimeFrom = (item, type = 'vertical') => {
  const from = getTime(item.from)
  const to = item.length && moment(item.from).add(item.length, 'h').format(TIME_FORMAT)
  if (type === 'vertical') {
    return (
      <div className="from-to">
        {from && (
          <div>
            <Trans>from {from} hrs.</Trans>
          </div>
        )}
        {to && (
          <div>
            <Trans>to {to} hrs.</Trans>
          </div>
        )}
      </div>
    )
  } else if (type === 'horizontal') {
    return (
      <div className="from-to">
        {from && (
          <span>
            <Trans>at {from} </Trans>
          </span>
        )}
        {to && (
          <span>
            <Trans>-{to} hrs.</Trans>
          </span>
        )}
      </div>
    )
  }
}
