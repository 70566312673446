import React, {useCallback, useEffect, useState} from 'react'
import {useStateWithCallbackLazy} from 'use-state-with-callback'
import {getScreenTimeoutTime} from '_helper/functions'
import moment from 'moment'
import {DATE_FORMAT} from '_helper/constants'
import httpClient from '_helper/httpClient'
import endpoints from '_config/endpoints'
import {config, useTransition} from 'react-spring'
import MagentaLogo from '_asset/img/MEC_static_logo_RGB.png'
import {Trans} from '@lingui/macro'
import Loader from '_component/Loader'
import NoWeekEvents from '_component/sideScreen/NoWeekEvents'
import WeekEventsHorizontal from '_component/mainEventStage/WeekEventsHorizontal'

const WeekEventsHorizontalScreen = () => {
  const [currentEvents, setCurrentEvents] = useStateWithCallbackLazy([])
  const [isLoading, setLoading] = useState(false)
  const [startingIndex, setStartingIndex] = useState(0)

  const setIndex = (prev, events) => {
    if (prev >= events.length - 1) {
      return 0
    } else {
      return prev + 1
    }
  }

  const startIterating = (events) => {
    setInterval(() => {
      setStartingIndex((prevState) => setIndex(prevState, events))
    }, getScreenTimeoutTime()) // set time out between the screens
  }

  const getAllEventsInWeek = (data) => {
    const allEvents = []
    data.forEach((day) => {
      day.events[0] &&
        day.events[0].forEach((event) => {
          allEvents.push({date: day.date, events: event})
        })
    })

    let count = 0
    let column = 'left'
    let slideIndex = 0
    let finalEventsPack = []
    let days = []
    const maxDaysLeft = 3
    const maxDaysRight = 4

    // two columns view
    allEvents.forEach((event) => {
      let newDate = !days.includes(event.date)

      if (column === 'left') {
        if (
          (days.length < maxDaysLeft || (days.length === maxDaysLeft && !newDate)) &&
          count + 1 <= 11
        ) {
          count += 1
          if (!finalEventsPack[slideIndex]) {
            finalEventsPack.push([])
          }
          if (!finalEventsPack[slideIndex][0]) {
            finalEventsPack[slideIndex].push([])
          }
          if (!days.includes(event.date)) {
            days.push(event.date)
          }
          finalEventsPack[slideIndex][0].push({date: event.date, event: event.events})
        } else {
          days = [event.date]
          column = 'right'
          count = 1
          if (!finalEventsPack[slideIndex][1]) {
            finalEventsPack[slideIndex].push([])
          }
          finalEventsPack[slideIndex][1].push({date: event.date, event: event.events})
        }
      } else if (column === 'right') {
        if (
          (days.length < maxDaysRight || (days.length === maxDaysRight && !newDate)) &&
          count + 1 <= 14
        ) {
          if (!days.includes(event.date)) {
            days.push(event.date)
          }
          count += 1
          finalEventsPack[slideIndex][1].push({date: event.date, event: event.events})
        } else {
          column = 'left'
          count = 1
          slideIndex += 1
          days = [event.date]

          if (!finalEventsPack[slideIndex]) {
            finalEventsPack.push([])
          }
          if (!finalEventsPack[slideIndex][0]) {
            finalEventsPack[slideIndex].push([])
          }
          finalEventsPack[slideIndex][0].push({date: event.date, event: event.events})
        }
      }
    })

    setCurrentEvents(finalEventsPack, startIterating)
  }

  const fetchWeekEvents = useCallback(() => {
    setLoading(true)
    const from = moment().startOf('isoWeek').format(DATE_FORMAT)
    const to = moment(from).add(6, 'd').format(DATE_FORMAT)

    const prepareParams = {
      from: from,
      to: to,
    }

    httpClient
      .get(endpoints.futureEvents, prepareParams)
      .then((res) => {
        setLoading(false)
        getAllEventsInWeek(res.data && res.data)
      })
      .catch((err) => {
        setLoading(false)
        console.error(err.response)
      })
  }, [])

  useEffect(() => {
    fetchWeekEvents()
  }, [fetchWeekEvents])

  // (item) => startingIndex causes Warning due to the same key for more elements in array
  // although this is necessary for proper enter and leave animation of array like data (events)
  const transitions = useTransition(currentEvents[startingIndex], (item) => startingIndex, {
    from: {opacity: 0, marginLeft: -3840, marginRight: 3840},
    enter: {opacity: 1, marginLeft: 0, marginRight: 0},
    leave: {opacity: 0, marginLeft: 3840, marginRight: -3840},
    config: config.slow,
  })

  return (
    <div className="week-events-horizontal-screen">
      {currentEvents.length > 0 && (
        <div className="title">
          <span>
            <Trans>Fully busy schedule</Trans>
          </span>
        </div>
      )}
      {isLoading ? (
        <Loader />
      ) : currentEvents[startingIndex] && currentEvents[startingIndex].length > 0 ? (
        <>
          <div className="wrapper">
            {transitions.map(({item, props, key}) => (
              <div className="content" key={key}>
                {currentEvents.length > 0 && startingIndex !== undefined && (
                  <WeekEventsHorizontal
                    events={currentEvents[startingIndex]}
                    transitionProps={props}
                  />
                )}
              </div>
            ))}
          </div>
          <div className="events-footer">
            <div className="magenta-logo">
              <img
                className="logo"
                src={MagentaLogo + '?timestamp=' + moment()}
                alt="magenta logo"
              />
            </div>
          </div>
          <div className="changes-in-program">
            <Trans>*Changes in the program reserved.</Trans>
          </div>
        </>
      ) : (
        <NoWeekEvents />
      )}
    </div>
  )
}

export default WeekEventsHorizontalScreen
