import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {getScreenTimeoutTime, getTimeFrom} from '_helper/functions'
import {config, useTransition, animated} from 'react-spring'
import {getDay, getEnumPart, getEventType} from '_helper/calendarFunctions'
import {DAY_MONTH_YEAR_FORMAT, ZONES} from '_helper/constants'
import moment from 'moment'

// library for adding ellipsis
// import HTMLEllipsis from 'react-lines-ellipsis/lib/html'

const CurrentEventsHorizontal = (props) => {
  const {events} = props

  const [displayedEventIndex, setDisplayedEventIndex] = useState(0)

  const setIndex = (prev) => {
    if (prev >= events.length - 1) {
      return 0
    } else {
      return prev + 1
    }
  }

  useEffect(() => {
    setInterval(() => {
      setDisplayedEventIndex((prev) => setIndex(prev))
    }, getScreenTimeoutTime())
  }, [])

  const transitions = useTransition(
    events.length > 0 && events[displayedEventIndex],
    (item) => item && item.id,
    {
      from: {opacity: 0, transform: 'translateX(-3840px)'},
      enter: {opacity: 1, transform: 'translateX(0px)'},
      leave: {opacity: 0, transform: 'translateX(3840px)'},
      config: config.slow,
    }
  )

  return (
    <>
      {transitions.map(({item, props, key}) => (
        <animated.div style={props} key={key} className="transition">
          <div className="single-event">
            <div className="title-zone">
              <div className="title">{item?.name}</div>
              <div className="zone">
                {item?.zone && <img src={getEnumPart(ZONES, item.zone, 'icon')} alt="event-logo" />}
              </div>
            </div>
            <div className="columns">
              <div className="left-column">
                <div className="type">{item?.type && getEventType(item)}</div>
                <div className="day">{item?.from && getDay(item.from)}</div>
                <div className="date">
                  {item?.from && moment(item?.from).format(DAY_MONTH_YEAR_FORMAT)}
                </div>
                <div className="time">{item && getTimeFrom(item, 'horizontal')}</div>
                <div
                  className="description"
                  dangerouslySetInnerHTML={{__html: item?.description && item.description}}
                />
                {/* informations about presenter to be uncommented once be provides data*/}
                <div className="presenter">
                  {/*  <img src={WomanHead} alt="presenter" />*/}
                  {/*  <span>*/}
                  {/*    <Trans>Presenting Petra Novakova</Trans>*/}
                  {/*  </span>*/}
                  {/*  <img src={item?.organization?.logoUrl} alt="presenter-logo" />*/}
                </div>
              </div>
            </div>
          </div>
          <div className="transparent-image">
            {item.horizontalPictureUrl && (
              <img src={item.horizontalPictureUrl + '?timestamp=' + moment()} alt="background" />
            )}
          </div>
        </animated.div>
      ))}
    </>
  )
}

CurrentEventsHorizontal.propTypes = {
  events: PropTypes.array,
}

export default CurrentEventsHorizontal
