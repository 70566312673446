import {Trans} from '@lingui/macro'
import React from 'react'

import CoworkingCage from '_asset/img/cafe.png'
import CoworkingKitchen from '_asset/img/kitchen.png'
import EasyEdu from '_asset/img/easy_edu.png'
import Gaming from '_asset/img/gaming.png'
import MagentaHome from '_asset/img/home.png'
import Podcast from '_asset/img/podcast.png'
import Tuning from '_asset/img/tuning_stage.png'

import {ReactComponent as Microphone} from '_asset/svg/microphone.svg'
import {ReactComponent as People} from '_asset/svg/people.svg'
import {ReactComponent as Public} from '_asset/svg/public_door.svg'
import {ReactComponent as Private} from '_asset/svg/private_door.svg'

import {resolveEnvironmentParam} from '_helper/functions'

export const ENVIRONMENT = process.env.NODE_ENV
export const GATEWAY_URL = resolveEnvironmentParam('GATEWAY_URL')
export const PUBLIC_API_KEY = resolveEnvironmentParam('PUBLIC_API_KEY')
export const SIDE_SCREEN_TIMEOUT = resolveEnvironmentParam('SIDE_SCREEN_TIMEOUT')

export const TIME_SLOT = [
  {value: 'MORNING', label: <Trans>morning</Trans>},
  {value: 'AFTERNOON', label: <Trans>afternoon</Trans>},
  {value: 'EVENING', label: <Trans>evening</Trans>},
  {value: 'ALL_DAY', label: <Trans>all day</Trans>},
]

export const PRIVACY = [
  {value: 'PRIVATE', label: <Trans>Private event</Trans>, icon: <Private />},
  {value: 'PUBLIC', label: <Trans>Public event</Trans>, icon: <Public />},
  {value: 'REGISTRATION', label: <Trans>Public event with Registration</Trans>, icon: <Private />},
]

export const PLATFORM = [
  {value: 'ONLINE', label: <Trans>Online event</Trans>, icon: <Microphone />},
  {value: 'OFFLINE', label: <Trans>Offline event</Trans>, icon: <People />},
]

export const DAYS = [
  <Trans>Monday</Trans>,
  <Trans>Tuesday</Trans>,
  <Trans>Wednesday</Trans>,
  <Trans>Thursday</Trans>,
  <Trans>Friday</Trans>,
  <Trans>Saturday</Trans>,
  <Trans>Sunday</Trans>,
]

export const MONTHS = [
  <Trans>JANUARY</Trans>,
  <Trans>FEBRUARY</Trans>,
  <Trans>MARCH</Trans>,
  <Trans>APRIL</Trans>,
  <Trans>MAY</Trans>,
  <Trans>JUNE</Trans>,
  <Trans>JULY</Trans>,
  <Trans>AUGUST</Trans>,
  <Trans>SEPTEMBER</Trans>,
  <Trans>OCTOBER</Trans>,
  <Trans>NOVEMBER</Trans>,
  <Trans>DECEMBER</Trans>,
]

export const DATE_FORMAT = 'YYYY-MM-DD'
export const DAY_MONTH_FORMAT = 'DD. MM.'
export const DAY_MONTH_YEAR_FORMAT = 'DD. MM. YYYY'
export const TIME_FORMAT = 'HH:mm'

export const ZONES = [
  {value: 'COWORKING_CAFE', label: <Trans>Co-working café</Trans>, icon: CoworkingCage},
  {value: 'COWORKING_KITCHEN', label: <Trans>Co-working kitchen</Trans>, icon: CoworkingKitchen},
  {value: 'PODCAST', label: <Trans>Podcast</Trans>, icon: Podcast},
  {value: 'MAGENTA_HOME', label: <Trans>Magenta home</Trans>, icon: MagentaHome},
  {value: 'GAMING', label: <Trans>Gaming</Trans>, icon: Gaming},
  {value: 'TUNING', label: <Trans>Custom</Trans>, icon: Tuning},
  {value: 'EASY_EDU', label: <Trans>EasyEdu</Trans>, icon: EasyEdu},
  {value: 'ALL', label: <Trans>All</Trans>, icon: EasyEdu},
]

export const EVENT_TYPES = [
  {value: 'PRESENTATION', label: <Trans>Presentation</Trans>},
  {value: 'WORKSHOP', label: <Trans>Workshop</Trans>},
  {value: 'LECTURE', label: <Trans>Lecture</Trans>},
  {value: 'SEMINAR', label: <Trans>Seminar</Trans>},
  {value: 'TRAINING', label: <Trans>Training</Trans>},
  {value: 'CONFERENCE', label: <Trans>Conference</Trans>},
  {value: 'TALKSHOW', label: <Trans>Talk show</Trans>},
  {value: 'COURSE', label: <Trans>Course</Trans>},
  {value: 'TEAMBUILDING', label: <Trans>Team building</Trans>},
  {value: 'NETWORKING', label: <Trans>Networking</Trans>},
  {value: 'VIRTUAL_EVENT', label: <Trans>Virtual event</Trans>},
  {value: 'INTERNAL_EVENT', label: <Trans>Internal event</Trans>},
  {value: 'CUSTOM', label: <Trans>Custom</Trans>},
]

export const ERROR_MSG = <Trans>Something went wrong!</Trans>
