import React from 'react'
import {Trans} from '@lingui/macro'

const WhatIsMagentaElement = () => {
  return (
    <div className="what-is-magenta">
      <div className="line">
        <Trans>I want to know,</Trans>
      </div>
      <div className="line">
        <Trans>what all</Trans>
      </div>
      <div className="line">
        <Trans>is magenta!</Trans>
      </div>
    </div>
  )
}

export default WhatIsMagentaElement
