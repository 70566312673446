import moment from 'moment'
import {DATE_FORMAT, DAY_MONTH_FORMAT, DAYS, EVENT_TYPES, TIME_FORMAT} from '_helper/constants'

// if the date is not a moment then create moment from the date
export const ensureMoment = (date) => {
  let momentDate = date
  if (!moment.isMoment(moment)) {
    momentDate = moment(date)
  }
  return momentDate
}

// index move due to the english week calendar starting with sunday
export const getDay = (date) => {
  const momentDate = ensureMoment(date)
  return momentDate.day() === 0 ? DAYS[6] : DAYS[momentDate.day() - 1]
}

export const getDayAndMonthDate = (date) => {
  const momentDate = ensureMoment(date)
  return momentDate.format(DAY_MONTH_FORMAT)
}

export const getEnumPart = (ENUM, value, part) => {
  return ENUM.filter((enu) => enu.value === value)[0][part]
}

export const getDate = (date) => {
  const momentDate = ensureMoment(date)
  return momentDate.format(DATE_FORMAT)
}

export const getTime = (date) => {
  const momentDate = ensureMoment(date)
  return momentDate.format(TIME_FORMAT)
}

export const getEndTime = (from, length) => {
  return moment(from).add(length, 'hours').format(TIME_FORMAT)
}

export const getEventType = (item) => {
  if (item.type !== 'CUSTOM') {
    return getEnumPart(EVENT_TYPES, item.type, 'label')
  } else {
    return item.customType
  }
}
