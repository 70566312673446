import React, {useCallback, useEffect, useState} from 'react'
import httpClient from '_helper/httpClient'
import endpoints from '_config/endpoints'
import Loader from '_component/Loader'
import CurrentEventsHorizontal from '_component/mainEventStage/CurrentEventsHorizontal'
import NoCurrentEvents from '_component/sideScreen/NoCurrentEvents'

const CurrentEventsHorizontalScreen = () => {
  const [currentEvents, setCurrentEvents] = useState([])
  const [isLoading, setLoading] = useState(false)

  const fetchCurrentEvents = useCallback(() => {
    setLoading(true)
    httpClient
      .get(endpoints.currentEvents)
      .then((res) => {
        setLoading(false)
        setCurrentEvents(res.data?.objects)
      })
      .catch((err) => {
        setLoading(false)
        console.error(err.response)
      })
  }, [])

  useEffect(() => {
    fetchCurrentEvents()
  }, [fetchCurrentEvents])

  return (
    <div className="current-events-horizontal-screen">
      {isLoading ? (
        <Loader />
      ) : currentEvents.length > 0 ? (
        <CurrentEventsHorizontal events={currentEvents} />
      ) : (
        <NoCurrentEvents />
      )}
    </div>
  )
}

export default CurrentEventsHorizontalScreen
