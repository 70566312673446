import React, {useCallback, useEffect, useState} from 'react'
import moment from 'moment'
import {DATE_FORMAT} from '_helper/constants'
import httpClient from '_helper/httpClient'
import endpoints from '_config/endpoints'
import {Trans} from '@lingui/macro'
import MagentaLogo from '_asset/img/MEC_static_logo_RGB.png'
import {useStateWithCallbackLazy} from 'use-state-with-callback'
import {config, useTransition} from 'react-spring'
import {getScreenTimeoutTime} from '_helper/functions'
import Loader from '_component/Loader'
import NoWeekEvents from '_component/sideScreen/NoWeekEvents'
import WeekEventsVertical from '_component/mainCommercialStage/WeekEventsVertical'

const WeekEventsVerticalScreen = () => {
  const [currentEvents, setCurrentEvents] = useStateWithCallbackLazy([])
  const [isLoading, setLoading] = useState(false)
  const [startingIndex, setStartingIndex] = useState(0)

  const setIndex = (prev, events) => {
    if (prev >= events.length - 1) {
      return 0
    } else {
      return prev + 1
    }
  }

  const startIterating = (events) => {
    setInterval(() => {
      setStartingIndex((prevState) => setIndex(prevState, events))
    }, getScreenTimeoutTime()) // set time out between the screens
  }

  const getAllEventsInWeek = (data) => {
    const allEvents = []
    data.forEach((day) => {
      day.events[0] &&
        day.events[0].forEach((event) => {
          allEvents.push({date: day.date, events: event})
        })
    })

    const allEventsInPack = []
    for (let i = 0; i < Math.ceil(allEvents.length / 20); i++) {
      const sliced = allEvents.slice(i * 20, i * 20 + 20)
      allEventsInPack.push(sliced)
    }

    setCurrentEvents(allEventsInPack, startIterating)
  }

  const fetchWeekEvents = useCallback(() => {
    setLoading(true)
    const from = moment().startOf('isoWeek').format(DATE_FORMAT)
    const to = moment(from).add(6, 'd').format(DATE_FORMAT)

    const prepareParams = {
      from: from,
      to: to,
    }

    httpClient
      .get(endpoints.futureEvents, prepareParams)
      .then((res) => {
        setLoading(false)
        getAllEventsInWeek(res.data && res.data)
      })
      .catch((err) => {
        setLoading(false)
        console.error(err.response)
      })
  }, [])

  useEffect(() => {
    fetchWeekEvents()
  }, [fetchWeekEvents])

  // (item) => startingIndex causes Warning due to the same key for more elements in array
  // although this is necessary for proper enter and leave animation of array like data (events)
  const transitions = useTransition(currentEvents[startingIndex], (item) => startingIndex, {
    from: {opacity: 0, marginLeft: -2160, marginRight: 2160},
    enter: {opacity: 1, marginLeft: 0, marginRight: 0},
    leave: {opacity: 0, marginLeft: 2160, marginRight: -2160},
    config: config.slow,
  })

  return (
    <div className="week-events-vertical-screen">
      {currentEvents?.[0]?.length > 0 && (
        <div className="title">
          <div className="magenta-logo">
            <img className="logo" src={MagentaLogo + '?timestamp=' + moment()} alt="magenta logo" />
          </div>
          <span>
            <Trans>Fully busy schedule</Trans>
          </span>
        </div>
      )}
      {isLoading ? (
        <Loader />
      ) : currentEvents?.[0]?.length > 0 ? (
        <>
          <div className="wrapper">
            {transitions.map(({item, props, key}) => (
              <div className="content" key={key}>
                {currentEvents[startingIndex] &&
                  currentEvents[startingIndex].length > 0 &&
                  startingIndex !== undefined && (
                    <WeekEventsVertical
                      events={currentEvents[startingIndex]}
                      transitionProps={props}
                    />
                  )}
              </div>
            ))}
          </div>
          <div className="changes-in-program">
            <Trans>*Changes in the program reserved.</Trans>
          </div>
        </>
      ) : (
        <NoWeekEvents />
      )}
    </div>
  )
}

export default WeekEventsVerticalScreen
