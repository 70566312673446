import React from 'react'
import {animated} from 'react-spring'
import {getDay, getEnumPart, getTime} from '_helper/calendarFunctions'
import moment from 'moment'
import {ZONES} from '_helper/constants'

const WeekEventsHorizontal = (props) => {
  const {events, transitionProps} = props

  const reduceEvents = (e) => {
    const leftEventsReduced = e.reduce((acc, d) => {
      const found = acc.find((a) => a.date === d.date)
      if (!found) {
        acc.push({date: d.date, events: [d.event]})
      } else {
        found.events.push(d.event)
      }
      return acc
    }, [])

    return leftEventsReduced
  }

  return (
    <animated.div style={transitionProps} className="week-events-horizontal transition">
      <div className="left-column">
        {events &&
          events[0]?.length > 0 &&
          reduceEvents(events[0]).map((event, index) => (
            <div className="event" key={index}>
              <div className="day-date">
                <div className="day">{event.date && getDay(event.date)}</div>
                <div className="date">
                  {event.date && moment(event.date).format('DD. MM. YYYY')}
                </div>
              </div>
              <div className="event-name-time">
                {event.events.map((singleEvent, index) => (
                  <div className="name-time" key={index}>
                    <div className="name"> {singleEvent.name}</div>
                    <div className="time"> {singleEvent.from && getTime(singleEvent.from)}</div>
                  </div>
                ))}
              </div>

              <div className="zone">
                {event.events && (
                  <img src={getEnumPart(ZONES, event.events[0].zone, 'icon')} alt="even-icon" />
                )}
              </div>
            </div>
          ))}
      </div>

      <div className="right-column">
        {events &&
          events[1]?.length > 0 &&
          reduceEvents(events[1]).map((event, index) => (
            <div className="event" key={index}>
              <div className="day-date">
                <div className="day">{event.date && getDay(event.date)}</div>
                <div className="date">
                  {event.date && moment(event.date).format('DD. MM. YYYY')}
                </div>
              </div>

              <div className="event-name-time">
                {event.events.map((singleEvent, index) => (
                  <div className="name-time" key={index}>
                    <div className="name"> {singleEvent.name}</div>
                    <div className="time"> {singleEvent.from && getTime(singleEvent.from)}</div>
                  </div>
                ))}
              </div>

              <div className="zone">
                {event.events && (
                  <img src={getEnumPart(ZONES, event.events[0].zone, 'icon')} alt="even-icon" />
                )}
              </div>
            </div>
          ))}
      </div>
    </animated.div>
  )
}

export default WeekEventsHorizontal
