import React, {useCallback, useEffect, useState} from 'react'
import {getScreenTimeoutTime, getTimeFrom} from '_helper/functions'
import {animated, config, useTransition} from 'react-spring'
import {getDay, getEnumPart, getEventType} from '_helper/calendarFunctions'
import {DATE_FORMAT, DAY_MONTH_YEAR_FORMAT, ZONES} from '_helper/constants'
import moment from 'moment'
import httpClient from '_helper/httpClient'
import endpoints from '_config/endpoints'
import {useStateWithCallbackLazy} from 'use-state-with-callback'
import Loader from '_component/Loader'
import NoCurrentEvents from '_component/sideScreen/NoCurrentEvents'

const CurrentEventsVerticalScreen = (props) => {
  const [isLoading, setLoading] = useState(false)
  const [currentEvents, setCurrentEvents] = useStateWithCallbackLazy([])
  const [startingIndex, setStartingIndex] = useState(0)

  const setIndex = (prev, events) => {
    if (prev >= events.length - 1) {
      return 0
    } else {
      return prev + 1
    }
  }

  const startIterating = (events) => {
    setInterval(() => {
      setStartingIndex((prev) => setIndex(prev, events))
    }, getScreenTimeoutTime())
  }

  const fetchWeekEvents = useCallback(() => {
    setLoading(true)
    const from = moment().startOf('isoWeek').format(DATE_FORMAT)
    const to = moment(from).add(6, 'd').format(DATE_FORMAT)

    const prepareParams = {
      from: from,
      to: to,
    }

    httpClient
      .get(endpoints.currentEvents, prepareParams)
      .then((res) => {
        setLoading(false)
        setCurrentEvents(res.data?.objects, startIterating)
        // set state
      })
      .catch((err) => {
        setLoading(false)
        console.error(err.response)
      })
  }, [])

  useEffect(() => {
    fetchWeekEvents()
  }, [fetchWeekEvents])

  const transitions = useTransition(
    currentEvents.length > 0 && currentEvents[startingIndex],
    (item) => item && item.id,
    {
      from: {opacity: 0, transform: 'translateX(-3840px)'},
      enter: {opacity: 1, transform: 'translateX(0px)'},
      leave: {opacity: 0, transform: 'translateX(3840px)'},
      config: config.slow,
    }
  )

  return (
    <div className="current-events-vertical-screen">
      {isLoading ? (
        <Loader />
      ) : currentEvents.length > 0 ? (
        transitions.map(({item, props, key}) => (
          <animated.div style={props} key={key} className="transition">
            <div className="single-event">
              <div className="zone">
                {item?.zone && <img src={getEnumPart(ZONES, item.zone, 'icon')} alt="event-logo" />}
              </div>
              <div className="column">
                <div className="title">{item?.name}</div>

                <div className="type">{item?.type && getEventType(item)}</div>
                <div className="day">{item?.from && getDay(item.from)}</div>
                <div className="date">
                  {item?.from && moment(item?.from).format(DAY_MONTH_YEAR_FORMAT)}
                </div>
                <div className="time">{item && getTimeFrom(item, 'horizontal')}</div>
                <div
                  className="description"
                  dangerouslySetInnerHTML={{__html: item?.description && item.description}}
                />
                {/* informations about presenter to be uncommented once be provides data*/}
                <div className="presenter">
                  {/*  <img src={WomanHead} alt="presenter" />*/}
                  {/*  <span>*/}
                  {/*    <Trans>Presenting Petra Novakova</Trans>*/}
                  {/*  </span>*/}
                  {/*  <img src={item?.organization?.logoUrl} alt="presenter-logo" />*/}
                </div>
              </div>
              <div className="transparent-image">
                {item.verticalPictureUrl && (
                  <img src={item.verticalPictureUrl + '?timestamp=' + moment()} alt="background" />
                )}
              </div>
            </div>
          </animated.div>
        ))
      ) : (
        <NoCurrentEvents />
      )}
    </div>
  )
}

export default CurrentEventsVerticalScreen
