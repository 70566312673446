import React from 'react'
import {Trans} from '@lingui/macro'

const NoWeekEvents = () => {
  return (
    <div className="no-week-events">
      <div className="no-events">
        <div className="text">
          <Trans>There are no events this week.</Trans>
        </div>
      </div>
    </div>
  )
}

export default NoWeekEvents
