import React from 'react'
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom'
import './App.scss'
import {LANGUAGE_CATALOGS} from '_config/language'
import {I18nProvider} from '@lingui/react'
import SideScreenPage from 'App/page/SideScreenPage'
import MainEventStagePage from 'App/page/MainEventStagePage'
import MainCommercialStagePage from 'App/page/MainCommercialStagePage'
import SingleEventPrint from '_component/print/SingleEventPrint'
import WeekEventsPrint from '_component/print/WeekEventsPrint'

const App = () => {
  return (
    <I18nProvider language={'cs'} catalogs={LANGUAGE_CATALOGS}>
      <Router>
        <Switch>
          <Route exact path="/main-commercial-stage" component={MainCommercialStagePage} />
          <Route exact path="/main-event-stage" component={MainEventStagePage} />
          <Route exact path="/side-screen" component={SideScreenPage} />
          <Route exact path="/print-event/:id/date/:date" component={SingleEventPrint} />
          <Route exact path="/print-week/:date" component={WeekEventsPrint} />
          <Redirect to="/side-screen" />
        </Switch>
      </Router>
    </I18nProvider>
  )
}

export default App
