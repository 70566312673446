import React, {useCallback, useEffect, useState} from 'react'
import WeekEventsVerticalScreen from '_component/mainCommercialStage/WeekEventsVerticalScreen'
import CurrentEventsVerticalScreen from '_component/mainCommercialStage/CurrentEventsVerticalScreen'

const MainCommercialStagePage = (props) => {
  const {
    location: {search},
  } = props

  const [config, setConfig] = useState({content: 'week'})

  const prepareContent = useCallback(() => {
    const paramsArray = search.substring(1).split('&')
    const params = {...config}

    paramsArray.forEach((param) => {
      const paramSplit = param.split('=')
      // ensure that params are valid
      if (paramSplit[0] === 'content') {
        params[paramSplit[0]] = paramSplit[1]
      }
    })
    setConfig(params)
  }, [])

  useEffect(() => {
    if (search) {
      prepareContent()
    }
  }, [prepareContent])

  const renderScreens = () => {
    const screens = []
    for (let screen in config) {
      if (config[screen] === 'week') {
        screens.push(<WeekEventsVerticalScreen key="week" />)
      } else {
        screens.push(<CurrentEventsVerticalScreen key="current" />)
      }
    }
    return screens
  }

  return <div className="main-commercial-stage">{renderScreens()}</div>
}

export default MainCommercialStagePage
