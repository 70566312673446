import React, {useCallback, useEffect, useState} from 'react'
import SideScreenLayout from '_component/sideScreen/SideScreenLayout'
import httpClient from '_helper/httpClient'
import endpoints from '_config/endpoints'
import CurrentEvents from '_component/sideScreen/CurrentEvents'
import NoCurrentEvents from '_component/sideScreen/NoCurrentEvents'
import WhatIsMagentaElement from '_component/sideScreen/WhatIsMagentaElement'
import Loader from '_component/Loader'

const CurrentEventsScreen = () => {
  const [currentEvents, setCurrentEvents] = useState([])
  const [isLoading, setLoading] = useState(false)

  const fetchCurrentEvents = useCallback(() => {
    setLoading(true)
    httpClient
      .get(endpoints.currentEvents)
      .then((res) => {
        setLoading(false)
        setCurrentEvents(res.data?.objects)
      })
      .catch((err) => {
        setLoading(false)
        console.error(err.response)
      })
  }, [])

  useEffect(() => {
    fetchCurrentEvents()
  }, [fetchCurrentEvents])

  return (
    <SideScreenLayout backgroundImage="lines" justifyFooter="spaced">
      <WhatIsMagentaElement />
      {isLoading ? (
        <Loader />
      ) : currentEvents.length > 0 ? (
        <CurrentEvents events={currentEvents} />
      ) : (
        <NoCurrentEvents />
      )}
    </SideScreenLayout>
  )
}

export default CurrentEventsScreen
