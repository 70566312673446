import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import httpClient from '_helper/httpClient'
import endpoints from '_config/endpoints'
import {getDay, getEnumPart, getTime} from '_helper/calendarFunctions'
import {DATE_FORMAT, ZONES} from '_helper/constants'
import moment from 'moment'
import Footer from '_component/Footer'
import Loader from '_component/Loader'
import MagentaLogo from '_asset/img/MEC_static_logo_RGB.png'
import {Trans} from '@lingui/macro'

const WeekEventsPrint = () => {
  let {date} = useParams()

  const [loading, setLoading] = useState(false)
  const [eventData, setEventData] = useState({})

  const getAllEventsInWeek = (data) => {
    const allEvents = []
    data.forEach((day) => {
      day.events[0] &&
        day.events[0].forEach((event) => {
          allEvents.push({date: day.date, events: event})
        })
    })

    let count = 0
    let slideIndex = 0
    let finalEventsPack = []
    let days = []
    const maxDaysOnOnePage = 4

    // create packs of events to display max 4 days and 15 events on one page
    allEvents.forEach((event) => {
      let newDate = !days.includes(event.date)

      if (
        (days.length < maxDaysOnOnePage || (days.length === maxDaysOnOnePage && !newDate)) &&
        count + 1 <= 15
      ) {
        count += 1
        if (!finalEventsPack[slideIndex]) {
          finalEventsPack.push([])
        }
        if (!days.includes(event.date)) {
          days.push(event.date)
        }
        finalEventsPack[slideIndex].push({date: event.date, event: event.events})
      } else {
        days = [event.date]
        count = 1
        slideIndex += 1
        if (!finalEventsPack[slideIndex]) {
          finalEventsPack.push([])
        }
        finalEventsPack[slideIndex].push({date: event.date, event: event.events})
      }
    })
    setEventData(finalEventsPack)
  }

  const eventsReduced = (events) =>
    events.reduce((acc, d) => {
      const found = acc.find((a) => a.date === d.date)
      if (!found) {
        acc.push({date: d.date, events: [d.event]})
      } else {
        found.events.push(d.event)
      }
      return acc
    }, [])

  useEffect(() => {
    if (!loading) {
      const from = date && moment(date).startOf('isoWeek').format(DATE_FORMAT)
      const to = from && moment(from).add(6, 'd').format(DATE_FORMAT)

      setLoading(true)
      httpClient
        .get(endpoints.calendar, {from: from, to: to})
        .then((res) => {
          setLoading(false)
          getAllEventsInWeek(res.data && res.data)
        })
        .catch((err) => {
          setLoading(false)
          console.error(err.response)
        })
    }
  }, [])

  return (
    <div className="print-week-layout">
      {loading ? (
        <Loader />
      ) : (
        eventData[0] &&
        eventData.map((events, index) => (
          <div key={index} className="week-events-page week-events-print">
            <div className="header">
              <img
                className="logo"
                src={MagentaLogo + '?timestamp='   + moment()}
                alt="magenta logo"
              />
              <div className="title">
                <Trans>Fully busy schedule</Trans>
              </div>
            </div>
            <div className="week-event">
              <div className="column">
                {eventsReduced(events).map((event, index) => (
                  <div className="event" key={index}>
                    <div className="day-date">
                      <div className="day">{event.date && getDay(event.date)}</div>
                      <div className="date">
                        {event.date && moment(event.date).format('DD. MM. YYYY')}
                      </div>
                    </div>

                    <div className="event-name-time">
                      {event.events.map((event, index) => (
                        <div className="name-time" key={index}>
                          <div className="name"> {event.name}</div>
                          <div className="time"> {event.from && getTime(event.from)}</div>
                        </div>
                      ))}
                    </div>

                    <div className="zone">
                      {event.events.length > 0 && (
                        <img
                          src={getEnumPart(ZONES, event.events[0].zone, 'icon')}
                          alt="even-icon"
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <Footer />
          </div>
        ))
      )}
    </div>
  )
}

export default WeekEventsPrint
