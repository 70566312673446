import React from 'react'
import {Trans} from '@lingui/macro'
import MagentaEXCELogo from '_asset/img/MEC_static_logo_RGB.png'
import moment from 'moment'

const NoCurrentEvents = () => {
  return (
    <div className="no-current-events-page">
      <div className="header">
        <img src={MagentaEXCELogo + '?timestamp=' + moment()} alt="event-logo" />
      </div>
      <div className="no-current-events">
        <div className="text">
          <Trans>There is no event currently running</Trans>
        </div>
      </div>
    </div>
  )
}

export default NoCurrentEvents
