import React from 'react'
import Footer from '_component/Footer'
import PropTypes from 'prop-types'

const SideScreenLayout = (props) => {
  const {children, backgroundImage, justifyFooter} = props
  return (
    <div className={`side-screen-layout ${backgroundImage}`}>
      {children}
      <Footer justify={justifyFooter} />
    </div>
  )
}

SideScreenLayout.propTypes = {
  children: PropTypes.node,
  backgroundImage: PropTypes.string,
  justifyFooter: PropTypes.string,
}

export default SideScreenLayout
