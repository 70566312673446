import React, {useCallback, useEffect, useState} from 'react'
import SideScreenLayout from '_component/sideScreen/SideScreenLayout'
import {useStateWithCallbackLazy} from 'use-state-with-callback'
import moment from 'moment'
import {DATE_FORMAT} from '_helper/constants'
import httpClient from '_helper/httpClient'
import endpoints from '_config/endpoints'
import {useTransition, config} from 'react-spring'
import MagentaLogo from '_asset/img/MEC_static_logo_RGB.png'
import {Trans} from '@lingui/macro'
import WeekEvents from '_component/sideScreen/WeekEvents'
import {getScreenTimeoutTime} from '_helper/functions'
import Loader from '_component/Loader'
import NoWeekEvents from '_component/sideScreen/NoWeekEvents'

const WeekEventsScreen = () => {
  const [currentEvents, setCurrentEvents] = useStateWithCallbackLazy([])
  const [isLoading, setLoading] = useState(false)
  const [startingIndex, setStartingIndex] = useState(0)

  const setIndex = (prev, events) => {
    if (prev >= events.length - 1) {
      return 0
    } else {
      return prev + 1
    }
  }

  const startIterating = (events) => {
    setInterval(() => {
      setStartingIndex((prevState) => setIndex(prevState, events))
    }, getScreenTimeoutTime()) // set time out between the screens
  }

  const getAllEventsInWeek = (data) => {
    const allEvents = []
    data.forEach((day) => {
      day.events[0] &&
        day.events[0].forEach((event) => {
          allEvents.push({date: day.date, events: event})
        })
    })

    let count = 0
    let slideIndex = 0
    let finalEventsPack = []
    let days = []
    const maxDaysOnOnePage = 4

    // create packs of events to display max 4 days and 15 events on one page
    allEvents.forEach((event) => {
      let newDate = !days.includes(event.date)

      if (
        (days.length < maxDaysOnOnePage || (days.length === maxDaysOnOnePage && !newDate)) &&
        count + 1 <= 15
      ) {
        count += 1
        if (!finalEventsPack[slideIndex]) {
          finalEventsPack.push([])
        }
        if (!days.includes(event.date)) {
          days.push(event.date)
        }
        finalEventsPack[slideIndex].push({date: event.date, event: event.events})
      } else {
        days = [event.date]
        count = 1
        slideIndex += 1
        if (!finalEventsPack[slideIndex]) {
          finalEventsPack.push([])
        }
        finalEventsPack[slideIndex].push({date: event.date, event: event.events})
      }
    })

    setCurrentEvents(finalEventsPack, startIterating)
  }

  const fetchWeekEvents = useCallback(() => {
    setLoading(true)
    const from = moment().startOf('isoWeek').format(DATE_FORMAT)
    const to = moment(from).add(6, 'd').format(DATE_FORMAT)

    const prepareParams = {
      from: from,
      to: to,
    }

    httpClient
      .get(endpoints.futureEvents, prepareParams)
      .then((res) => {
        setLoading(false)
        getAllEventsInWeek(res.data && res.data)
      })
      .catch((err) => {
        setLoading(false)
        console.error(err.response)
      })
  }, [])

  useEffect(() => {
    fetchWeekEvents()
  }, [fetchWeekEvents])

  // (item) => startingIndex causes Warning due to the same key for more elements in array
  // although this is necessary for proper enter and leave animation of array like data (events)
  const transitions = useTransition(
    currentEvents.length > 0 && currentEvents[startingIndex],
    (item) => startingIndex,
    {
      from: {opacity: 0, marginLeft: -1080, marginRight: 1080},
      enter: {opacity: 1, marginLeft: 0, marginRight: 0},
      leave: {opacity: 0, marginLeft: 1080, marginRight: -1080},
      config: config.slow,
    }
  )

  return (
    <SideScreenLayout backgroundImage="dots">
      <div className="week-events-page">
        {currentEvents.length > 0 && (
          <div className="header">
            <img className="logo" src={MagentaLogo + '?timestamp=' + moment()} alt="magenta logo" />
            <div className="title">
              <Trans>Fully busy schedule</Trans>
            </div>
          </div>
        )}
        {isLoading ? (
          <Loader />
        ) : currentEvents.length > 0 ? (
          transitions.map(
            ({item, props, key}) =>
              // this key={key} is same for currently shown set of events there for the Warning
              startingIndex !== undefined && (
                <WeekEvents
                  key={key}
                  events={currentEvents[startingIndex]}
                  transitionProps={props}
                />
              )
          )
        ) : (
          <NoWeekEvents />
        )}
      </div>
    </SideScreenLayout>
  )
}

export default WeekEventsScreen
