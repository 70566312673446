import React from 'react'
import {getDay, getEnumPart, getTime} from '_helper/calendarFunctions'
import {ZONES} from '_helper/constants'
import moment from 'moment'
import {animated} from 'react-spring'
import PropTypes from 'prop-types'

const WeekEvents = (props) => {
  const {events, transitionProps, key} = props
  // if the description would be too long in all events it would overflow
  // therefore I trim every event description to max. 350 chars
  const eventsReduced = events.reduce((acc, d) => {
    const found = acc.find((a) => a.date === d.date)
    if (!found) {
      acc.push({date: d.date, events: [d.event]})
    } else {
      found.events.push(d.event)
    }
    return acc
  }, [])

  return (
    <animated.div style={transitionProps} className="week-event transition" key={key}>
      <div className="column">
        {events &&
          eventsReduced.map((event, index) => (
            <div className="event" key={index}>
              <div className="day-date">
                <div className="day">{event.date && getDay(event.date)}</div>
                <div className="date">
                  {event.date && moment(event.date).format('DD. MM. YYYY')}
                </div>
              </div>

              <div className="event-name-time">
                {event.events.map((event, index) => (
                  <div className="name-time" key={index}>
                    <div className="name"> {event.name}</div>
                    <div className="time"> {event.from && getTime(event.from)}</div>
                  </div>
                ))}
              </div>

              <div className="zone">
                {event.events.length > 0 && (
                  <img src={getEnumPart(ZONES, event.events[0].zone, 'icon')} alt="even-icon" />
                )}
              </div>
            </div>
          ))}
      </div>

      {/*<div className="logo">*/}
      {/*  {event.zone && <img src={getEnumPart(ZONES, event.zone, 'icon')} alt="event-logo" />}*/}
      {/*</div>*/}
      {/*<div className="event-detail">*/}
      {/*  <div className="event-date-time">*/}
      {/*    <div className="day">{event.from && getDay(event.from)}</div>*/}
      {/*    <div className="date">{event.from && getDayAndMonthDate(event.from)}</div>*/}
      {/*    <div className="time">{event.from && getTimeFrom(event)}</div>*/}
      {/*    <div className="year">{event.from && moment(event.from).year()}</div>*/}
      {/*  </div>*/}
      {/*  <div className="event-type-description">*/}
      {/*    <div className="type">{event.name && event.name}</div>*/}
      {/*    <div className="description">*/}
      {/*      <span>{event?.organization?.name}&ensp;&ndash;&ensp;</span>*/}
      {/*      {event.type && getEnumPart(EVENT_TYPES, event.type, 'label')}*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </animated.div>
  )
}

WeekEvents.propTypes = {
  event: PropTypes.object,
  transitionProps: PropTypes.object,
}

export default WeekEvents
