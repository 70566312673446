import React, {useCallback, useEffect, useState} from 'react'
import WeekEventsHorizontalScreen from '_component/mainEventStage/WeekEventsHorizontalScreen'
import CurrentEventsHorizontalScreen from '_component/mainEventStage/CurrentEventsHorizontalScreen'

const MainEventStagePage = (props) => {
  const {
    location: {search},
  } = props

  const [config, setConfig] = useState({content: 'current'})

  const prepareContent = useCallback(() => {
    const paramsArray = search.substring(1).split('&')
    const params = {...config}
    paramsArray.forEach((param) => {
      const paramSplit = param.split('=')
      // ensure that params are valid
      if (paramSplit[0] === 'content') {
        params[paramSplit[0]] = paramSplit[1]
      }
    })
    setConfig(params)
  }, [])

  useEffect(() => {
    if (search) {
      prepareContent()
    }
  }, [prepareContent])

  const renderScreens = () => {
    const screens = []
    for (let screen in config) {
      if (config[screen] === 'week') {
        screens.push(<WeekEventsHorizontalScreen key="week" />)
      } else {
        screens.push(<CurrentEventsHorizontalScreen key="current" />)
      }
    }
    return screens
  }

  return <div className="main-event-screen">{renderScreens()}</div>
}

export default MainEventStagePage
