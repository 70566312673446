import React from 'react'
import TmobileLogo from '_asset/img/logoTmobileMagenta.svg'
import PropTypes from 'prop-types'
import cx from 'classnames'
import moment from 'moment'

const Footer = (props) => {
  const {justify} = props

  const footerClasses = cx({
    footer: true,
    spaced: justify === 'spaced',
  })

  return (
    <div className={footerClasses}>
      <span className="link">www.t-mobile.cz/magenta-experience</span>
      <img className="logo" src={TmobileLogo + '?timestamp=' + moment()} alt="t-mobile logo" />
    </div>
  )
}

Footer.propTypes = {
  justify: PropTypes.string,
}

export default Footer
